import * as _oauth3 from "oauth-1.0a";

var _oauth2 = "default" in _oauth3 ? _oauth3.default : _oauth3;

import * as _crypto3 from "crypto";

var _crypto2 = "default" in _crypto3 ? _crypto3.default : _crypto3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOauthHeader = addOauthHeader;

var _oauth = _interopRequireDefault(_oauth2);

var _crypto = _interopRequireDefault(_crypto2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function addOauthHeader(tokens) {
  return function (requestParams) {
    const {
      method,
      url,
      headers,
      queryParams
    } = requestParams;
    const oauth = (0, _oauth.default)({
      consumer: {
        key: tokens.OAUTH_CONSUMER_KEY,
        secret: tokens.OAUTH_CONSUMER_SECRET
      },
      signature_method: "HMAC-SHA512",

      hash_function(baseString, key) {
        return _crypto.default.createHmac("sha512", key).update(baseString).digest("base64");
      }

    });
    const requestData = {
      url,
      method,
      data: queryParams
    };
    const oauthData = oauth.authorize(requestData, {
      key: tokens.API_OAUTH_TOKEN,
      secret: tokens.API_OAUTH_TOKEN_SECRET
    });
    const oauthHeaders = oauth.toHeader(oauthData);
    return { ...requestParams,
      headers: { ...oauthHeaders,
        ...headers
      }
    };
  };
}

export default exports;
export const __esModule = exports.__esModule;
const _addOauthHeader = exports.addOauthHeader;
export { _addOauthHeader as addOauthHeader };